const photos = [
    {
        src: 'https://vivek.kandathil.ca/photos/A_23.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_3 copy 5.jpg',
        width: 4.5,
        height: 3,
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_20.jpg',
        width: 4.5,
        height: 4
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_22.jpg',
        width: 4,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_3 copy 4.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_21.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/Night2.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/splant1.jpg',
        width: 5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/splant2.jpg',
        width: 5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/parliament.jpg',
        width: 9,
        height: 6
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_2.jpg',
        width: 4.8,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/rainy1.jpg',
        width: 4,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_6.jpg',
        width: 4,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_7 2.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_7 copy 2.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/A_9.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/36190657380_cf003f191c_o.jpg',
        width: 5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/Chateau_Laurier.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/schamplain.jpg',
        width: 4.6,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/streets4.jpg',
        width: 6,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/senna2.jpg',
        width: 4.8,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/senna4.jpg',
        width: 4.8,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/sky.jpeg',
        width: 5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/stone.jpg',
        width: 5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/stone2.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/splant4.jpg',
        width: 5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/stulips.jpg',
        width: 3.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/streets.jpg',
        width: 4.5,
        height: 3
    },
    {
        src: 'https://vivek.kandathil.ca/photos/IMG_5575.jpg',
        width: 4.5,
        height: 6.5
    },
    {
        src: 'https://vivek.kandathil.ca/photos/Gatineau_A.JPG',
        width: 3,
        height: 3
    },
];

export default photos